@import 'styles/mixins';

/* Attributes */
.xa-attributes-elements {
  margin-bottom: 1.6rem;
  display: flex
}

.xa-attributes-list {
  @include flex(flex);
  @include flex-direction(column);
}

.xa-attributes-list-head {
  background: var(--xa-primary);
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include flex(flex);
  @include flex-align-items(stretch);
  @include flex-direction(row);
  font-weight: 700;
  position: sticky;
  top: -0.8rem;

  .dark & {
    color: var(--xa-black);
  }
}

.xa-attributes-list-row {
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include flex(flex);
  @include flex-align-items(stretch);
  @include flex-direction(row);
}

.xa-attributes-list-label {
  background: rgba(var(--xa-secondary-rgb), 0.25);
  min-width: 13rem;
  max-width: 13rem;
  padding: 0.8rem;
  width: 15rem;

  &.full-width {
    width: 100%;
  }
}

.xa-attributes-list-value {
  padding: 0.8rem;
  // width: 15rem;
  flex-basis: fit-content;
  word-wrap: break-word;
  word-break: auto-phrase;
  max-width: 30rem;
  white-space: normal;
}

.xa-attributes-list-value-true {
  color: var(--xa-success);
  font-size: 2rem;
}

.xa-attributes-list-value-unknown {
  font-size: 2rem;
}

.xa-eyevi-title {
  display: block;
  margin-bottom: 0.8rem;
  text-align: left;
  width: 100%;
}

.eyevi-iframe{
  width: 90vw
}

.xa-eyevi-row {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  gap: 1.6rem;

  >div {
    width: 100%;
  }
}

.xa-eyevi-btns {
  margin-top: 1.6rem;
}

.xa-imageHub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  flex-direction: column;

  .xa-imageHub__main-swiper {
    display: flex;
    position: relative;
    align-items: center
  }

 .xa-imageHub__arrow {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    transition: .3s all;
  }
  .xa-imageHub__arrow:hover{
    transform: scale(.94);
  }

  .xa-imageHub__swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    &.thumb{
      height: 100px;
    }
  }

  .xa-imageHub__swiper{
    width: 480px;
    height: 300px;
  }
  .xa-imageHub-thumbs{
    width: 100%;
    margin-top: 10px;
  }
  .xa-imageHub__asset-name{
    color: #fff;
    font-size: 14;
    font-weight: 600;
  }
}

.asset_hub--container{
  display: flex;
  height: auto;
}

.xa-asset_hub--info_container{
  margin: 0 10px;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
}

.xa_asset_hub--legend_container{
  min-width: 25rem;
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
  overflow-y: scroll;
}

.empty_request--container{
  min-width: 300px;
}

.xa_asset_hub--options_container{
  // height: 470px;
  position: relative;
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
  width: auto;
  overflow: scroll;
}

.xa_asset_hub--options_container .hide-summary {
  display: none;
}

.hide-summary .toggle-switch-option[data-value="change_assetHub"] {
  display: none;
}

/* Charts */
.xa-chart_asset {
  position: relative;
  height: 40.6rem;
  margin-top: 1.6rem;
  min-width: 50rem;
  width: 100%;
}

.xa-chart_asset div div svg{
  width: 600px;
}

.xa-chart_asset-footer {
  background: rgba(var(--xa-secondary-rgb), 0.25);
  border-top: 0.1rem solid var(--xa-secondary);
  margin-top: auto;
  margin-left: -0.4rem;
  padding: 0.8rem;
  text-align: center;
  width: calc(100% + 0.8rem);

  .dark & {
    background: rgba(var(--xa-text-alt-rgb), 0.25);
  }

  &:empty {
    display: none;
  }

  button {
    margin-top: 0;
    white-space: nowrap;
    width: 100%;

    + button {
      margin-left: 0.8rem;
    }
  }
}

.xa_asset_hub--options-select {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  gap: 0.8rem;

  .custom-switch {
    @include border-radius(0.4rem);
    white-space: nowrap;

    label {
      @include border-radius(0.4rem);
    }
  }
}

.modal-header {
  padding-left: 8px;
}

.modal-title-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-title-in-container{
  text-transform: capitalize;
}
.modal-refresh-button {
  cursor: pointer;
}

.rotating {
  animation: spin 1s linear infinite;
}

.modal-title-container-asset-hub {
  display: flex;
  align-items: center;
  gap: 0;
}

/* Inspections */

.events{
  left: auto !important;
  top: 30px!important;
}

.events-wrapper{
  position: inherit!important;

}

div.xa_asset_inspection_records--container{
  position: relative;
  height: 44rem;
  width: 100%;
}

div.xa_asset_inspection_records{
  overflow: hidden;
  top: 20px;
}

div.xa_asset_inspection_records--data{
  height: 320px;
  margin-top: 58px;
  overflow: scroll;
  transition: height 0.3s ease;
}

div.xa_asset_inspection_records div div ul:first-of-type li{
  display: none!important;
}

.timeline-eventline{
  background-color: #d1d1d1!important;
  // border-radius: 50%;
  
  .dark & {
    background-color: #1e2922!important;
  }
}

.timeline-eventline:nth-child(2){
  background-color: var(--xa-selected)!important;
}

li.past.dot-label span, li.future.dot-label span{
  width: 18px!important;
  height: 18px!important;
  bottom: -8px!important;
  background: #BDBDBD!important;
  border: 2px solid var(--xa-selected)!important;

  .dark & {
    background: #2f3933!important;
  }
}

li.present.dot-label span{
  background-color: var(--xa-selected)!important;
  border: 2px solid var(--xa-selected)!important;
  box-shadow: 0px 0px 1px 1px #0000001a;
  border: none!important;
  animation: pulsate 2s ease-in-out infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.events-bar{
  font-size: x-small;
}

.button-back, .button-forward{
  background: #D1D1D3;
  color: var(--xa-selected)!important;
  top: 30px!important;
  
  .dark & {
    background: #181923;
    border: 2px solid #1e2922!important;
  }
}

.button-back:hover, .button-forward:hover{
  border: 2px solid var(--xa-selected)!important;
}

.button-back.enabled svg, .button-forward.enabled svg{
  fill: var(--xa-selected)!important;
}

.button-back.disabled, .button-forward.disabled{
  opacity: 0.4;
  cursor: auto!important;
  border: none!important;
  
  .dark & {
    opacity: 0.1;
  }
} 

.button-back.disabled svg, .button-forward.disabled svg{
  left: 50%!important;
  transform: translate(-50%, -50%)!important;
}

.hide-left-arrow .button-back, .hide-right-arrow .button-forward {
  opacity: 0.1;
  cursor: auto!important;
}

.xa_asset_inspection_records--footer{
  width: calc(100% + 0.8rem);
  min-height: 45px;
  // margin-top: 45px;
  padding: 1rem;
  // border-top: 0.1rem solid var(--xa-secondary);
  display: flex; 
  align-items: center;
  justify-content: center;
  text-align: center;
  // background: rgba(var(--xa-secondary-rgb), 0.25);
  position: relative; /* Ensure footer stays in flow */
  // padding-bottom: 40px;
}

.xa_asset_inspection_records--footer > span{
  width: 10rem;
  min-width: 9rem;
  margin: 0;
  cursor: pointer;
  transition: background 0.3s;
}

.fixed-summary {
  position: fixed;
  bottom: 10px; 
  z-index: 10; 
  background-color: var(--xa-primary); 
  color: white;
  padding: 8px 16px;
  // border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.xa_asset_inspection_records--footer > span:hover{
  background: #036332!important;

  .dark & {
    background: #4f8b72!important;
  }
}

.xa_asset_inspection_records--footer > span.exit-state:hover {
  background: #A61D2A!important; 
}

.xa_asset_inspection_records--footer > span.exit-state {
  background: #D3273E!important;
}

@keyframes blowUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes blowDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    // transform: scale(0.5);
  }
  100% {
    opacity: 0;
    // transform: scale(0);
  }
}

.xa_asset_inspection_report--modal-overlay {
  display: flex;
  height: 322px;
  position: absolute;
  top: 76px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F1F1F1;
  z-index: 1000;
  overflow-y: auto;
  animation: blowUp 0.3s ease-out forwards;
  
  .dark & {
    background-color: #191923;
  }
}

.xa_asset_inspection_report--modal-content {
  width: 100%;
  max-width: 600px;
  background-color: #F1F1F1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .dark & {
    background-color: #191923;
  }
}

.xa_asset_inspection_report--scrollable-content {
  max-height: 60vh;
  overflow-y: auto;
}

.xa_asset_inspection_report--pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  // border-top: 0.1rem solid var(--xa-secondary);
  // background-color: #E9E9EA;
  position: sticky;
  bottom: 0;
  width: 100%;

  .dark & {
    // background-color: #1E1F28;
  }
}

.xa_asset_inspection_report--pagination button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px 10px;
}

.xa_asset_inspection_report--pagination button:disabled {
  opacity: 0.2;
  cursor: default;
}

.xa_asset_inspection_report--scrollable-content::-webkit-scrollbar {
  display: block;
  background-color: #E9E9EA!important;
  width: 0.2em!important;
  
  .dark & {
    background: #191923!important;
    width: 0.2em!important;
  }
}

.xa_asset_inspection_report--scrollable-content::-webkit-scrollbar-track {
  background: #F1F1F1!important;
  
  .dark & {
    background: #191923!important;
  }
}

.xa_asset_inspection_report--modal-overlay-exit {
  animation: blowDown 0.3s ease-out forwards;
}

.xa_asset_inspection_report--pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

::-webkit-scrollbar {
  display: none;
}

